import {useEffect, useState} from "react";

interface IZoomProps {
    zoom: number,
    setZoom: Function
}

function Zoom(props: IZoomProps) {
    function validateZoom(scale: number) {
        if(scale > 1.9 || scale < 0.1)
            return;
        else
            props.setZoom(scale)
    }

    return (
        <div className={'Zoom'}>
            <button className={'Zoom-In'} onClick={() => validateZoom( props.zoom + .1)}>+</button>
            <div className={'Zoom-Track'}>
                <input type="range" step={.1} value={props.zoom} min={0.1} max={1.9} onChange={(e) => validateZoom(Number(e.target.value))}/>
            </div>
            <button className={'Zoom-Out'} onClick={() => validateZoom( props.zoom - .1)}>-</button>
        </div>
    );
}

export default Zoom;