import "./Person.scss"

interface IPersonProps{
   person: {
       id: number,
       img: string,
       text: string,
       position: Array<number>,
       orientation: string,
       name: string,
   };
}

function Person(props: IPersonProps) {
    return (
        <div className={'Person ' + props.person.name}>
            <div className={'Tooltip '+props.person.orientation} style={{left: props.person.position[0] + '%', top: props.person.position[1] +'%' }}>
                <span >{props.person.text}</span>
            </div>
            <img src={props.person.img} alt="person"/>
        </div>
    );
}

export default Person;