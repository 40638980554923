/* previews */
import ch1 from "../../../resources/comics/previews/ch1.jpg";
import ch2 from "../../../resources/comics/previews/ch2.png";
import ch3 from "../../../resources/comics/previews/ch3.png";
import ch4 from "../../../resources/comics/previews/ch4.jpg";
import ch5 from "../../../resources/comics/previews/ch5.jpg";
import ch6 from "../../../resources/comics/previews/ch6.jpg";
import ch7 from "../../../resources/comics/previews/ch7.jpg";
import ch8 from "../../../resources/comics/previews/ch8.jpg";
import ch9 from "../../../resources/comics/previews/ch9.jpg";
import ch10 from "../../../resources/comics/previews/ch10.jpg";
import ch11 from "../../../resources/comics/previews/ch11.jpg";
import ch12 from "../../../resources/comics/previews/ch12.jpg";
import ch13 from "../../../resources/comics/previews/ch13.jpg";
import ch14 from "../../../resources/comics/previews/ch14.jpg";

/* comics */
import chapter1 from "../../../resources/comics/chapters/chapter1.jpg";
import chapter2 from "../../../resources/comics/chapters/chapter2.jpg";
import chapter3 from "../../../resources/comics/chapters/chapter3.jpg";
import chapter4 from "../../../resources/comics/chapters/chapter4.jpg";
import chapter5 from "../../../resources/comics/chapters/chapter5.jpg";
import chapter6 from "../../../resources/comics/chapters/chapter6.jpg";
import chapter7 from "../../../resources/comics/chapters/chapter7.jpg";
import chapter8 from "../../../resources/comics/chapters/chapter8.jpg";
import chapter9 from "../../../resources/comics/chapters/chapter9.jpg";
import chapter10 from "../../../resources/comics/chapters/chapter10.jpg";
import chapter11 from "../../../resources/comics/chapters/chapter11.jpg";
import chapter12 from "../../../resources/comics/chapters/chapter12.jpg";
import chapter13 from "../../../resources/comics/chapters/chapter13.jpg";
import chapter14 from "../../../resources/comics/chapters/chapter14.jpg";

export default [
  {
    chapter: 1,
    name: "Being an ITS superhero",
    preview: ch1,
    comic: chapter1
  },
  {
    chapter: 2,
    name: "Grave rider - Deryakulas revival",
    preview: ch2,
    comic: chapter2
  },
  {
    chapter: 3,
    name: "Close Encounters of the Fifth Kind",
    preview: ch3,
    comic: chapter3
  },
  {
    chapter: 4,
    name: "Dragon Riders",
    preview: ch4,
    comic: chapter4
  },
  {
    chapter: 5,
    name: "Buzz Off",
    preview: ch5,
    comic: chapter5
  },
  {
    chapter: 6,
    name: "The Anomaly",
    preview: ch6,
    comic: chapter6
  },
  {
    chapter: 7,
    name: "We Come In Peace",
    preview: ch7,
    comic: chapter7
  },
  {
    chapter: 8,
    name: "Deryakula Daily",
    preview: ch8,
    comic: chapter8
  },
  {
    chapter: 9,
    name: "2 Cute 2 Spook",
    preview: ch9,
    comic: chapter9
  },
  {
    chapter: 10,
    name: "Let There Be Light!",
    preview: ch10,
    comic: chapter10
  },
  {
    chapter: 11,
    name: "Diamond Cut Diamond",
    preview: ch11,
    comic: chapter11
  },
  {
    chapter: 12,
    name: "Red Ocean Riders",
    preview: ch12,
    comic: chapter12
  },
  {
    chapter: 13,
    name: "IT Reality Show Time",
    preview: ch13,
    comic: chapter13
  },
  {
    chapter: 14,
    name: "Home, Sweet Home",
    preview: ch14,
    comic: chapter14
  },
]