import {useRef, useState} from "react";
import {Swiper, SwiperRef, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from "swiper";
import Story from "./Story/Story";

import 'swiper/swiper-bundle.css';
import './Stories.scss';

import stories from "./StoriesData";
import arrow from "../../../resources/comics/arrow.svg";
import arrowBack from "../../../resources/comics/arrow-back.svg";

function Stories() {
    const prevBtn = useRef<HTMLDivElement>(null);
    const nextBtn = useRef<HTMLDivElement>(null);
    const pagination = useRef<HTMLDivElement>(null);
    const swiperRef = useRef<SwiperRef>(null);

    const [_, setInit] = useState(false)

    return (
        <div className={'Stories'} id={'stories'}>
            <div className="Stories-header">
                <div className={'nav-prev'} ref={prevBtn}  onClick={() => {
                    swiperRef.current?.swiper.slidePrev()
                }}>
                    <img src={arrowBack} alt={'previous'} />
                </div>
                <h2 className="main-title">The Stories</h2>
                <div className={'nav-next'} ref={nextBtn} onClick={() => {
                    swiperRef.current?.swiper.slideNext()
                }}>
                    <img src={arrow} alt={'next'} />
                </div>
            </div>
            <h3 className="subtitle">use arrows or scroll up and down</h3>
            <Swiper ref={swiperRef}
                loop={true}
                centeredSlides={true}
                spaceBetween={50}
                slidesPerView='auto'
                modules={[Pagination, Autoplay]}
                initialSlide={0}
                autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                }}
                pagination={{
                    el: pagination.current!
                }}
                onBeforeInit={swiper => {
                    swiper.pagination.el = pagination.current!
                    swiper.pagination.update()
                }}
                onInit={() => setInit(true)}
                breakpoints={{
                        320 : {
                            spaceBetween: 30,
                            pagination: {
                                type: "fraction"
                            }
                        },
                        1100: {
                            spaceBetween: 50,
                            pagination: {
                                type: "bullets"
                            }
                        },
                        3000: {
                            spaceBetween: 80,
                        }
                    }}
            >
                {
                    stories.map(story => (
                        <SwiperSlide key={story.chapter}>
                            <Story
                                story={story}
                            />
                         </SwiperSlide>
                    ))
                }
            </Swiper>
            <div ref={pagination} className={'pagination'}></div>
        </div>
    );
}

export default Stories;