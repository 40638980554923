import React, {useEffect, useRef} from 'react';
import './Header.scss';
import logo from "../../../resources/logo.svg";
import burger from "../../../resources/comics/burger.svg";

interface IHeaderProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
}

function Header(props: IHeaderProps) {
    const header = useRef<HTMLDivElement>(null);

    const addBg = function () {
        if(window.scrollY > 10)
            header.current?.classList.add('scrolled')
        else
            header.current?.classList.remove('scrolled')
    }

    useEffect(() => {
        window.addEventListener('scroll', addBg)

        return () => {
            window.removeEventListener('scroll', addBg)
        }
    })

    return (
        <div className="Header" ref={header}>
            <div className="content">
                <div className={'logo'}>
                    <div><img className={'logo-img'} src={logo}  alt={'logo'}/></div>
                    <div className={'logo-text'}>Your Trusted Software <br /> Development Partner</div>
                </div>
                <div className={'burger'} onClick={() => props.setShowSideMenu(!props.showSideMenu)}>
                    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_108_20685)">
                            <path d="M38.1595 11.8199L32.6065 11.9765L26.5802 12.135C24.3119 12.1866 21.8177 12.2228 19.1975 12.2381C16.5774 12.2534 14.0846 12.2381 11.8148 12.2037L8.59394 12.1465L5.78706 12.0815C4.07167 12.0357 2.68343 11.9879 1.72513 11.9459C1.24453 11.923 0.872499 11.902 0.620619 11.8791C0.494678 11.8695 0.39769 11.8581 0.331101 11.8466C0.300951 11.8425 0.271297 11.8335 0.242798 11.8199C0.27364 11.8335 0.305821 11.8413 0.338339 11.8428C0.40348 11.8523 0.499021 11.8581 0.627856 11.8619C0.881185 11.8619 1.25321 11.8619 1.73381 11.8294C2.69357 11.7645 4.08325 11.5716 5.7972 11.2565C7.51114 10.9413 9.55079 10.4906 11.8235 10.1182C14.2672 9.70098 16.7277 9.47839 19.1917 9.45166C21.6597 9.45601 24.1251 9.65819 26.5744 10.0571C28.8443 10.4104 30.8883 10.8363 32.6051 11.1476C34.3219 11.4589 35.7087 11.6633 36.6685 11.7435C37.1491 11.7855 37.5211 11.8027 37.7744 11.8103L38.1595 11.8199Z" fill="#808080"/>
                            <path d="M38.1595 18.4912C38.1595 18.4912 37.6282 18.4912 36.6699 18.581C35.7116 18.6707 34.3234 18.835 32.608 19.0814L29.804 19.4633C28.7907 19.5932 27.718 19.7269 26.5846 19.8052C25.4511 19.8835 24.2597 19.9045 23.025 19.872C21.7902 19.8396 20.509 19.788 19.2062 19.746C17.9034 19.704 16.6194 19.6448 15.3846 19.5703C14.1498 19.4958 12.9541 19.4137 11.8235 19.3316L5.79141 18.8866C4.07601 18.7662 2.68778 18.6956 1.72802 18.6287C1.24887 18.6001 0.876842 18.5753 0.624961 18.5523C0.499021 18.5409 0.402033 18.5313 0.335444 18.518C0.303824 18.5143 0.272689 18.5053 0.242798 18.4912C0.27364 18.5049 0.305821 18.5126 0.338339 18.5141C0.40348 18.5256 0.499021 18.5313 0.627856 18.5371C0.881185 18.5504 1.25321 18.5562 1.73237 18.5542C2.69212 18.5542 4.08036 18.5027 5.79575 18.4034C7.51114 18.3041 9.55948 18.1513 11.8221 17.9775C12.9555 17.8934 14.1382 17.8018 15.3788 17.7235C16.6194 17.6452 17.8904 17.5726 19.199 17.5172L23.0192 17.3415C24.2525 17.2937 25.4424 17.2957 26.5773 17.3625C27.7122 17.4293 28.7892 17.542 29.7982 17.6566L32.6065 18.0004C34.3205 18.22 35.7087 18.3709 36.6685 18.4282C37.6282 18.4855 38.1595 18.4912 38.1595 18.4912Z" fill="#808080"/>
                            <path d="M0.242853 25.8442C0.242853 25.8442 0.774118 25.8442 1.73242 25.932C2.69073 26.0199 4.07896 26.1879 5.79436 26.4343L8.59833 26.8163C9.61165 26.9461 10.6843 27.0798 11.8178 27.1581C12.9512 27.2364 14.1426 27.2555 15.3774 27.2231C16.6122 27.1906 17.8933 27.139 19.1961 27.0989C20.499 27.0588 21.783 26.9958 23.0178 26.9232C24.2526 26.8506 25.4483 26.7666 26.5788 26.6845L32.6109 26.2395C34.3263 26.1192 35.7146 26.039 36.6743 25.9797C37.1535 25.9511 37.5255 25.9282 37.7774 25.9034C37.9033 25.8919 38.0003 25.8823 38.0669 25.8709C38.0985 25.8672 38.1297 25.8582 38.1595 25.8442C38.1288 25.8599 38.0967 25.8708 38.064 25.8766C37.9984 25.8868 37.9019 25.8944 37.7745 25.8995C37.5212 25.911 37.1491 25.9186 36.67 25.9148C35.7102 25.9148 34.322 25.8652 32.6066 25.7639C30.8912 25.6627 28.8429 25.5137 26.5803 25.34C25.4468 25.2559 24.2641 25.1623 23.0236 25.0859C21.783 25.0095 20.512 24.9351 19.2034 24.8778L15.3832 24.704C14.1498 24.6562 12.9599 24.6581 11.825 24.7231C10.6901 24.788 9.61309 24.9141 8.60413 25.0191L5.7958 25.3629C4.07896 25.5806 2.69073 25.7315 1.73097 25.7926C0.771223 25.8537 0.242853 25.8442 0.242853 25.8442Z" fill="#808080"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_108_20685">
                                <rect width="37.5" height="19.3966" fill="white" transform="translate(0.219849 8.58105)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Header;
