import React, {useEffect, useState} from 'react';

import "./Comics.scss";

import Header from "./Header/Header";
import SideMenu from "./SideMenu/SideMenu"
import Banner from "./Banner/Banner"
import Stories from "./Stories/Stories"
import Team from "./Team/Team";

import Meerkat from "../../resources/comics/meerkat.png";
import Footer from "../../resources/comics/footer-1.svg";
import whatsapp from "../../components/SideMenu/SideMenuFooter/whatsapp.svg";
import telegram from "../../components/SideMenu/SideMenuFooter/telegram.svg";
import mail from "../../components/SideMenu/SideMenuFooter/mail.svg";
import linked from "../../components/SideMenu/SideMenuFooter/linked_in.svg";
import {Link} from "react-router-dom";

function Comics() {
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        window.scroll(0,0)
    }, [])

   return(
       <div className={'comics'} >
           <Header
               showSideMenu={showSidebar}
               setShowSideMenu={setShowSidebar}
           />
           <SideMenu
               showSideMenu={showSidebar}
               setShowSideMenu={setShowSidebar}
           />
           <Banner />
           <Stories />
           <Team />
           <img src={Meerkat} alt={'Meerkat'} className={'Meerkat'}/>
           <img src={Footer} alt={'Footer'} className={'Footer'}/>
           <div className={'SideMenuFooter'}>
               <p className={'footer-text'}>Inspired and created by
                   <Link to={'/'}> Tahion.pro</Link>
               </p>
               <div className={'contacts'}>
                <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://wa.me/972546655677?text=Hello, Dan!" className={'icon'}
                    ><img src={whatsapp} alt={'whatsapp'} /></a>
                </span>
                   <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer"
                       href="https://telegram.me/The7thsky2021" className={'icon'}
                    ><img src={telegram} alt={'telegram'} /></a>
                </span>
                   <span className={'item'}>
                    <a href="mailto:hello@tahion.pro" className={'icon'}><img src={mail} alt={'mail'} /></a>
                </span>
                   <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer"
                       href={"https://www.linkedin.com/company/tahion-pro"}
                       className={'icon'}
                    ><img src={linked} alt={'linked'} /></a>
                </span>
               </div>
           </div>

       </div>
   );
}

export default Comics;