import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from "react-router-dom";
import './App.scss';
import Header from "../Header/Header";
import FirstScreen from "../FirstScreen/FirstScreen";
import SideMenu from "../SideMenu/SideMenu";
import OurClients from "../OurClients/OurClients";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import OurIndustries from "../OurIndustries/OurIndustries";
import Portfolio from "../Portfolio/Portfolio";
import OurTeam from "../OurTeam/OurTeam";
import OurContacts from "../OurContacts/OurContacts";
import Testimonials from "../Testimonials/Testimonials"; 
import Cookies from "../Cookies/Cookies";

import Vika from "../../resources/Vika.svg";

function App() {
    const [showSideMenu, setShowSideMenu] = useState(false);

    const ComicIntro = useRef<HTMLDivElement>(null);
    const Placeholder = useRef<HTMLDivElement>(null);

    let navigate = useNavigate();

    const handleScroll = (e: Event) => {
        if (Placeholder.current && ComicIntro.current) {
            if (Placeholder.current.getBoundingClientRect().top <= window.innerHeight) {
                    ComicIntro.current.style.bottom = "-2%"
            }
            else {
                if(ComicIntro.current)
                    ComicIntro.current.style.bottom = "-50%"
            }
        }
    }

    const clickLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        navigate("/comics");
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => handleScroll(e));
        return () => {
            window.removeEventListener('scroll', (e) => handleScroll(e) );
        };
    }, []);

    return (
        <div id={"App"} className="App">
            <Header showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu}/>
            <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
            <FirstScreen/>
            <OurClients />
            <Testimonials />
            <AboutUs />
            <Services />
            <div className={'jointIndAndPortfolioBG'}>
                <OurIndustries />
                <Portfolio />
            </div>
            <OurTeam />
            <OurContacts />
            <Cookies />
            <div className="spaceholder" ref={Placeholder} />
            <div className={'Vika'} ref={ComicIntro}>
                <div className={'Vika-bubble'}>
                    <p>
                        For us it’s not <br />
                        just about a work
                    </p>
                    <a
                        style={{cursor: 'pointer'}}
                        onClick={(e) => clickLink(e)}>
                        Would you like
                        to know more?
                    </a>
                </div>
                <img src={Vika} alt={'Vika'} />
            </div>
        </div>
    );
}

export default App;
