/*IMAGES*/
import alex from "../../../../resources/people/alex.png";
import andrey from "../../../../resources/people/andrey.png";
import vadim from "../../../../resources/people/vadim.png";
import vika from "../../../../resources/people/vika.png";
import dan from "../../../../resources/people/dan.png";
import tanya from "../../../../resources/people/tanya.png";
import helen from "../../../../resources/people/helen.png";
import dima from "../../../../resources/people/dima.png";

const people = [
    {
        id: 0,
        img: alex,
        name: 'alex',
        text: 'Funny',
        position: [70, 20],
        orientation: 'right'
    },
    {
        id: 1,
        img: andrey,
        name: 'andrey',
        text: 'Instructive',
        position: [-45, -15],
        orientation: 'left'
    },
    {
        id: 2,
        img: vadim,
        name: 'vadim',
        text: 'Original',
        position: [-40, -10],
        orientation: 'left'
    },
    {
        id: 3,
        img: vika,
        name: 'vika',
        text: 'About us',
        position: [10, -5],
        orientation: 'left'
    },
    {
        id: 4,
        img: dan,
        name: 'dan',
        text: 'In pictures',
        position: [40, -15],
        orientation: 'right'
    },
    {
        id: 5,
        img: tanya,
        name: 'tanya',
        text: 'For you',
        position: [35, -10],
        orientation: 'right'
    },
    {
        id: 6,
        img: helen,
        name: 'helen',
        text: 'Artfully',
        position: [-10, -15],
        orientation: 'left'
    },
    {
        id: 7,
        img: dima,
        name: 'dima',
        text: 'Fabulous',
        position: [-10, -10],
        orientation: 'left'
    }
];

export default people;