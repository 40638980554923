import React from "react";
import './Banner.scss';
import People from './People/PeopleData';
import Person from "./People/Person";

import road from "./road.svg"
import overlay from "./overlay.png"
import overlayMobile from "./overlay-mobile.png"

function Banner() {
    return(
        <div className={'Banner'} id={'introduction'}>
            <div className={'People'}>
                {
                    People.map((person) => (
                        <Person
                            key={person.id}
                            person={person}
                        />
                    ))
                }
                <img src={road} className={'Road'} alt={'road'}/>
                <img src={window.innerWidth > 500 ? overlay : overlayMobile} className={'Overlay'} alt={'comics'}/>
            </div>
        </div>
    )
}

export default Banner;