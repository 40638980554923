import { Link } from "react-router-dom";

import tape from '../../../../resources/comics/tape.svg';

interface IStoryProps{
    story: {
        chapter:number,
        name: string,
        preview: string
    }
}

function Story(props: IStoryProps) {
    return(
        <div className={'Story'}>
            <div className={'Story-info'}>
                <span className={'Chapter'}>Chapter {props.story.chapter}</span>
                <h4 className={'Story-Name'}>{props.story.name}</h4>
                <div></div>
            </div>
            <Link to={`/comics/chapter-${props.story.chapter}`}  className={'Preview'}>
                <img src={tape} className={'Tape'} alt={'tape'} />
                <img src={props.story.preview} alt={props.story.name} className={'Image'}/>
            </Link>
        </div>
    );
}

export default Story;