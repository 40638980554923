import {useEffect, useState} from "react";

interface IZoomContainerProps {
    src: string,
    zoom: number
}

function ZoomContainer(props: IZoomContainerProps) {
    const [position, setPosition] = useState("50% 50%");
    const [isZoomed, setIsZoomed] = useState(false);

    useEffect(() => {
        if(props.zoom > 1)
            setIsZoomed(true);
        else{
            setIsZoomed(false);
            setPosition(`${50}% ${0}% `);
        }
    }, [props.zoom, isZoomed])

    function zoomInPosition(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        const zoomer = e.currentTarget.getBoundingClientRect();
        let x, y, offsetX, offsetY;
        offsetX = e.clientX - zoomer.x;
        offsetY = e.clientY - zoomer.y;
        x = (offsetX / zoomer.width) * 100;
        y = (offsetY / zoomer.height) * 100;
        setPosition(`${x}% ${y}% `);
    }

    function handleMove(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (isZoomed) {
            zoomInPosition(e);
        }
    }

    return(
        <div className={'Chapter-container' + (isZoomed ? ' zoomed' : '')}
             style={{
                 backgroundImage: `url(${props.src})`,
                 backgroundSize: props.zoom*100 + '%',
                 backgroundPosition: position,
                 backgroundRepeat: 'no-repeat',
                 backgroundColor: '#fff'
             }}
             onMouseMove={(e) => handleMove(e)}
        >
            <img src={props.src} alt={'Chapter'} style={{
                opacity:  0
            }}/>
        </div>
    );
}

export default ZoomContainer;