import React, {useEffect, useRef} from 'react';
import SideMenuFooter from "../../../components/SideMenu/SideMenuFooter/SideMenuFooter";
import "./SideMenu.scss";

interface ISideMenuProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
}

function SideMenu(props: ISideMenuProps) {
    const menu = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(props.showSideMenu)
            menu.current?.classList.add('active')
        else
            menu.current?.classList.remove('active')
    }, [props.showSideMenu])

    return(
        <div className={'Sidebar'} ref={menu}>
            <ul className={'Links'}>
                <li className={'Link'} onClick={() => {props.setShowSideMenu(false)}}>
                    <a href="/comics#introduction">
                        Introduction
                    </a>
                </li>
                <li className={'Link'} onClick={() => {props.setShowSideMenu(false)}}>
                    <a href="/comics#stories">
                        the stories
                    </a>
                </li>
                <li className={'Link'} onClick={() => {props.setShowSideMenu(false)}}>
                    <a href="/comics#team">
                        meet the team
                    </a>
                </li>
            </ul>
            <SideMenuFooter footer={'side-menu'}/>
        </div>
    );
}

export default SideMenu