import TeamData from './TeamData';
import Member from "./Member";
import {Swiper, SwiperRef, SwiperSlide} from 'swiper/react';
import {useRef} from "react";

import 'swiper/swiper-bundle.css';

import "./Team.scss";
import arrow from "../../../resources/comics/arrow.svg";
import arrowBack from "../../../resources/comics/arrow-back.svg";

function Team() {
    const prevBtn = useRef<HTMLDivElement>(null);
    const nextBtn = useRef<HTMLDivElement>(null);
    const swiperRef = useRef<SwiperRef>(null);

    return(
        <div className={'Team'} id={'team'}>
            <h2 className="main-title">meet the team</h2>
            <h3 className="subtitle">Real superheroes in comics and in real life</h3>
            <div className={'Members'}>
                <div className={'nav-prev'} ref={prevBtn}  onClick={() => {
                    swiperRef.current?.swiper.slidePrev()
                }}>
                    <img src={arrowBack} alt={'previous'} />
                </div>
                <Swiper ref={swiperRef}
                        slidesPerView={5}
                        slidesPerGroup={5}
                        spaceBetween={40}
                        breakpoints={{
                            320: {
                                slidesPerGroup: 1,
                                slidesPerView: 1,
                                spaceBetween: 15
                            },
                            768: {
                                slidesPerGroup: 2,
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            1200: {
                                slidesPerGroup: 3,
                                slidesPerView: 3,
                                spaceBetween: 30
                            },
                            1600: {
                                slidesPerGroup: 5,
                                slidesPerView: 5,
                                spaceBetween: 20
                            }
                        }}
                >
                    {
                        TeamData.map(member => (
                            <SwiperSlide key={member.id}>
                                <Member member={member} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div className={'nav-next'} ref={nextBtn} onClick={() => {
                    swiperRef.current?.swiper.slideNext()
                }}>
                    <img src={arrow} alt={'next'} />
                </div>
            </div>
        </div>
    );
}

export default Team;