import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import stories from "../Comics/Stories/StoriesData";

import Header from "./Header/Header";
import SideMenu from "../Comics/SideMenu/SideMenu"
import Zoom from './Zoom/Zoom'
import ZoomContainer from './ZoomContainer/ZoomContainer'

import './Comic.scss';

import arrow from "../../resources/comics/arrow.svg";
import arrowBack from "../../resources/comics/arrow-back.svg";

function Comic() {
    const params = useParams();

    const [showSidebar, setShowSidebar] = useState(false);
    const [showPreloader, setShowPreloader] = useState(true);
    const [chapterNum, setChapterNum] = useState(0);
    const [zoom, setZoom] = useState<number>(1);
    const [chapter, setChapter] = useState({
        chapter: 0,
        name: '',
        preview: '',
        comic: ''
    });

    useEffect(() => {
        setShowPreloader(true)
        window.scroll(0,0)
        findChapter(chapterNum)
        setShowPreloader(false)
    }, [])

    useEffect(() => {
        setShowPreloader(true)
        window.scroll(0,0)
        setZoom(1);
        findChapter(chapterNum)
        setShowPreloader(false)
    }, [chapterNum])

    useEffect(() => {
        let chNum = params.chapter?.split('-')[1]
        setChapterNum(Number(chNum));
    }, [params])

    function findChapter(num: number){
        let chapterData = stories.find(item => item.chapter === num)
        if(chapterData){
            setChapter(chapterData);
        }
    }

    function nextChapter() {
        setShowPreloader(true)
        if(chapterNum + 1 > stories.length)
            setChapterNum(1);
        else
            setChapterNum(chapterNum + 1)
        setShowPreloader(false)
    }

    function prevChapter() {
        setShowPreloader(true)
        if(chapterNum - 1 <= 0)
            setChapterNum(stories.length)
        else
            setChapterNum(chapterNum - 1)
        setShowPreloader(false)
    }

    return (
        <div className={'comics single-comic'}>
            <Header
                showSideMenu={showSidebar}
                setShowSideMenu={setShowSidebar}
            />
            <SideMenu
                showSideMenu={showSidebar}
                setShowSideMenu={setShowSidebar}
            />
            {
                window.innerWidth > 1200 &&
                <Zoom
                    zoom={zoom}
                    setZoom={setZoom}
                />
            }
            <div className="View">
                <div className={'Comic'}>
                    {
                        showPreloader && <div className={'Preloader'}><span></span></div>
                    }
                    <ZoomContainer
                        src={chapter.comic}
                        zoom={zoom}
                    />
                </div>
            </div>
            <div className={'Navigation'}>
                <div className={'Prev'} onClick={prevChapter}>
                    <img src={arrowBack} alt={'previous'}/>
                </div>
                <span>see next</span>
                <div className={'Next'} onClick={nextChapter}>
                    <img src={arrow} alt={'next'} />
                </div>
            </div>
        </div>
    );
}

export default Comic;