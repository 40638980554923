    /* Cartoon avatars */
    import vadim from '../../../resources/comics/team/cartoons/vadim.png';
    import dan from '../../../resources/comics/team/cartoons/dan.png';
    import tanya from '../../../resources/comics/team/cartoons/tanya.png';
    import andrey from '../../../resources/comics/team/cartoons/andrey.png';
    import vika from '../../../resources/comics/team/cartoons/vika.png';
    import alex from '../../../resources/comics/team/cartoons/alex.png';
    import maxim from '../../../resources/comics/team/cartoons/maxim.png';
    import helen from '../../../resources/comics/team/cartoons/helen.png';
    import alena from '../../../resources/comics/team/cartoons/alena.png';
    import vasya from '../../../resources/comics/team/cartoons/vasya.png';
    import igor from '../../../resources/comics/team/cartoons/igor.png';
    import dima from '../../../resources/comics/team/cartoons/dima.png';
    import nadya from '../../../resources/comics/team/cartoons/nadya.png';

    /* Photos */
    import vadimPhoto from '../../../resources/comics/team/photos/vadim.png';
    import danPhoto from '../../../resources/comics/team/photos/dan.png';
    import tanyaPhoto from '../../../resources/comics/team/photos/tanya.png';
    import andreyPhoto from '../../../resources/comics/team/photos/andrey.png';
    import vikaPhoto from '../../../resources/comics/team/photos/vika.png';
    import alexPhoto from '../../../resources/comics/team/photos/alex.png';
    import maximPhoto from '../../../resources/comics/team/photos/maxim.png';
    import helenPhoto from '../../../resources/comics/team/photos/helen.png';
    import alenaPhoto from '../../../resources/comics/team/photos/alena.png';
    import vasyaPhoto from '../../../resources/comics/team/photos/vasya.png';
    import igorPhoto from '../../../resources/comics/team/photos/igor.png';
    import dimaPhoto from '../../../resources/comics/team/photos/dima.png';
    import nadyaPhoto from '../../../resources/comics/team/photos/nadya.png';

const team = [
     {
         id: 0,
         name: 'Mr. V',
         real_name: 'Vadim Ogarkov',
         position: 'VP R&D - Co-Founder',
         avatar: vadim,
         photo: vadimPhoto,
         link: 'https://www.linkedin.com/in/vadim-ogarkov-9a84308b/'
     },
     {
         id: 1,
         name: 'Mr. D',
         real_name: 'Dan Smirnov',
         position: 'Chief Executive Officer - Co-Founder',
         avatar: dan,
         photo: danPhoto,
         link: 'https://www.linkedin.com/in/dansmirnov/'
     },
     {
         id: 2,
         name: 'Mrs. T',
         real_name: 'Tatyana Ogarkova',
         position: 'PMO, Product Visionary',
         avatar: tanya,
         photo: tanyaPhoto,
         link: 'https://www.linkedin.com/in/tatsiana-ogarkova/'
     },
     {
         id: 3,
         name: 'Mr. A',
         real_name: 'Andrey Grishman',
         position: 'Chief Technical Officer',
         avatar: andrey,
         photo: andreyPhoto,
         link: 'https://www.linkedin.com/in/agrishman/'
     },
     {
         id: 4,
         name: 'Mrs. V',
         real_name: 'Victoria Efremova',
         position: 'Chief Operating Officer',
         avatar: vika,
         photo: vikaPhoto,
         link: 'https://www.linkedin.com/in/efremovavictoria/'
     },
     {
         id: 5,
         name: 'Mr. A',
         real_name: 'Aleksander Karaka',
         position: 'Project Manager',
         avatar: alex,
         photo: alexPhoto,
         link: 'https://www.linkedin.com/in/alex-karaka/'
     },
     {
         id: 6,
         name: 'Mr. Deryacula',
         real_name: 'Maksim Deryabin',
         position: 'Developer',
         avatar: maxim,
         photo: maximPhoto,
         link: ''
     },
     {
         id: 7,
         name: 'Mrs. L',
         real_name: 'Elena Butko',
         position: 'UI Designer',
         avatar: helen,
         photo: helenPhoto,
         link: ''
     },
     {
         id: 8,
         name: 'Mrs. A',
         real_name: 'Aliona Pashkevich',
         position: 'Developer',
         avatar: alena,
         photo: alenaPhoto,
         link: 'https://www.linkedin.com/in/aliona-pashkevich-3961ab226/'
     },
     {
         id: 9,
         name: 'Mr. V',
         real_name: 'Vasili Khapankou',
         position: 'Developer',
         avatar: vasya,
         photo: vasyaPhoto,
         link: 'https://www.linkedin.com/in/vasili-khapankou-7151a5208/'
     },
     {
         id: 10,
         name: 'Mr. I',
         real_name: 'Igor Efremov',
         position: 'Quality Assurance',
         avatar: igor,
         photo: igorPhoto,
         link: 'https://www.linkedin.com/in/igor-efremov-a1a539200/'
     },
     {
         id: 11,
         name: 'Mr. D',
         real_name: 'Dmitriy Bordachev',
         position: 'Sr. Team Leader',
         avatar: dima,
         photo: dimaPhoto,
         link: 'https://www.linkedin.com/in/belaryc/'
     },
     {
         id: 12,
         name: 'Ms. N',
         real_name: 'Nadezhda Podkovyrova',
         position: 'Developer',
         avatar: nadya,
         photo: nadyaPhoto,
         link: 'https://www.linkedin.com/in/nadezhda-podkovyrova/'
     },
 ];

export default team;